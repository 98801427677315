import {
  IconButton,
  Stack,
  Tab,
  Tabs,
  styled,
  useMediaQuery,
  useTheme,
  Menu as MuiMenu,
  MenuItem,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { FC, useCallback } from "react";
import logo from "../assets/main-logo.png";
import { Contacts } from "./Contacts";
import { Car, useCarSwitchContext } from "../hooks/useCarSwitch";
import { useNavigate } from "react-router-dom";
import { theme } from "../theme";

export const Header: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack>
      <Stack direction="row" gap={2} p={2}>
        <img src={logo} alt="logo" width="200px" height={80} />
        <Menu />
      </Stack>
      {isMobile ? (
        <Box pr={2}>
          <Contacts />
        </Box>
      ) : null}
    </Stack>
  );
};

const Menu: FC = () => {
  const { cars, selectedCar } = useCarSwitchContext();
  const navigate = useNavigate();

  const handleChange = useCallback(
    (event: React.SyntheticEvent, car: Car) => {
      navigate(`/${car.id}`);
    },
    [navigate]
  );
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      flex={1}
      pr={2}
      sx={(theme) => ({
        [theme.breakpoints.down(992)]: {
          gap: isSmallScreen ? 0 : 2,
          flexDirection: "column",
          alignItems: "flex-start",
        },
      })}
    >
      {isSmallScreen ? (
        <Stack alignSelf={"flex-end"} direction="row">
          <HeaderMenu />
        </Stack>
      ) : (
        <StyledTabs
          value={selectedCar}
          onChange={handleChange}
          allowScrollButtonsMobile
        >
          {cars.map((car) => (
            <StyledTab key={car.label} label={car.label} value={car} />
          ))}
        </StyledTabs>
      )}
      <Stack alignSelf={isSmallScreen ? "flex-end" : "inherit"}>
        {isMobile ? null : <Contacts />}
      </Stack>
    </Stack>
  );
};

const StyledTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#000",
  },
  "& .MuiTabs-flexContainer": {
    flexWrap: "wrap",
  },
});

interface StyledTabProps {
  value: Car;
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(() => ({
  color: "rgba(0, 0, 0, 0.85)",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.025em",
  textTransform: "none",
  padding: 0,
  minWidth: 0,
  "&:not(:last-child)": {
    marginRight: theme.spacing(2.5),
  },

  "&:hover": {
    color: "#acacac",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#000",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

const HeaderMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();

  const handleClose = (id: string) => {
    navigate(`/${id}`);

    setAnchorEl(null);
  };

  const { cars, selectedCar } = useCarSwitchContext();

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <MuiMenu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {cars.map((option) => (
          <MenuItem
            selected={selectedCar?.id === option.id}
            key={option.id}
            onClick={() => handleClose(option.id)}
            sx={{
              color: "rgba(0, 0, 0, 0.85)",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "0.025em",
              textTransform: "none",
              "&:hover": {
                color: "#acacac",
                opacity: 1,
              },
              "&.Mui-selected": {
                color: "#000",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "#d1eaff",
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </MuiMenu>
    </div>
  );
};
