import { PaletteColorOptions, TypeText, createTheme } from "@mui/material";

const primary: PaletteColorOptions = {
  main: "#fff",
};

const text: Partial<TypeText> = {
  primary: "#fcd2cf",
  secondary: "#fcd2cf",
  disabled: "#fcd2cf",
};

export const theme = createTheme({
  palette: {
    primary: primary,
    text: text,
    background: {
      default: "#fcd2cf",
      paper: "#fff",
    },
    divider: "#fcd2cf",
  },
  typography: {
    fontFamily: "IBM Plex Sans Devanagari, Helvetica, sans-serif",
    fontSize: 12,
    fontWeightRegular: 400,
    fontWeightMedium: 500,

    fontWeightBold: 700,

    htmlFontSize: 12,
    h1: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 40,
      lineHeight: "47px",
      color: "#000000",
    },
    h2: {
      fontSize: 26,
      fontWeight: 400,
      lineHeight: "28px",
      color: "#000",
    },
    h3: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: "22px",
      color: primary.main,
    },

    h4: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "18px",
      letterSpacing: "0.02em",
      color: "#fcd2cf",
    },
    body1: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "18px",
      letterSpacing: "0.01em",
    },
    body2: {
      fontSize: 11,
      fontWeight: 400,
      lineHeight: "14px",
      letterSpacing: "0.01em",
    },

    caption: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: "14px",
      letterSpacing: "0.01em",
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 992,
      lg: 1100,
      xl: 1400,
    },
  },
});
