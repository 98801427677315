import { Car } from "./hooks/useCarSwitch";

import vwT1ImageMain from "./assets/images/vw-t1/blue-main.png";
import vwT1Image1 from "./assets/images/vw-t1/blue-1.jpg";
import vwT1Image2 from "./assets/images/vw-t1/blue-2.jpg";
import vwT1Image3 from "./assets/images/vw-t1/blue-3.jpg";
import vwT1Image4 from "./assets/images/vw-t1/blue-4.jpg";
import vwT1Image5 from "./assets/images/vw-t1/blue-5.jpg";
import vwT1Image6 from "./assets/images/vw-t1/blue-6.jpg";

import vwT2GreenImageMain from "./assets/images/vw-t2/green-main.jpg";
import vwT2RedImageMain from "./assets/images/vw-t2/red-main.jpg";
import vwT2Image1 from "./assets/images/vw-t2/1.jpg";
import vwT2Image2 from "./assets/images/vw-t2/2.jpg";
import vwT2Image3 from "./assets/images/vw-t2/3.jpg";
import vwT2Image4 from "./assets/images/vw-t2/4.jpg";
import vwT2Image5 from "./assets/images/vw-t2/5.jpg";
import vwT2Image6 from "./assets/images/vw-t2/green-1.jpg";
import vwT2Image7 from "./assets/images/vw-t2/green-2.jpg";
import vwT2Image8 from "./assets/images/vw-t2/red-1.jpg";
import vwT2Image9 from "./assets/images/vw-t2/red-2.jpg";

import vwThingMain from "./assets/images/vw-thing/main.png";
import vwThingImage1 from "./assets/images/vw-thing/1.jpg";
import vwThingImage2 from "./assets/images/vw-thing/2.jpg";
import vwThingImage3 from "./assets/images/vw-thing/3.jpg";
import vwThingImage4 from "./assets/images/vw-thing/4.jpg";
import vwThingImage5 from "./assets/images/vw-thing/5.jpg";

import vwBeetleMain from "./assets/images/vw-beetle/main.png";
import vwBeetleImage1 from "./assets/images/vw-beetle/1.jpg";
import vwBeetleImage2 from "./assets/images/vw-beetle/2.jpg";
import vwBeetleImage3 from "./assets/images/vw-beetle/3.jpg";
import vwBeetleImage4 from "./assets/images/vw-beetle/4.jpg";
import vwBeetleImage5 from "./assets/images/vw-beetle/5.jpg";

import vwKarmannGhiaMain from "./assets/images/vw-karmann-chia/main.png";
import vwKarmannGhia1 from "./assets/images/vw-karmann-chia/1.jpg";
import vwKarmannGhia2 from "./assets/images/vw-karmann-chia/2.jpg";
import vwKarmannGhia3 from "./assets/images/vw-karmann-chia/3.jpg";
import vwKarmannGhia4 from "./assets/images/vw-karmann-chia/4.jpg";
import vwKarmannGhia5 from "./assets/images/vw-karmann-chia/5.jpg";
import vwKarmannGhia6 from "./assets/images/vw-karmann-chia/6.jpg";
import vwKarmannGhia7 from "./assets/images/vw-karmann-chia/7.jpg";

import mgMagnetteZBMain from "./assets/images/mg-magnette-zb/main.png";
import mgMagnetteZB1 from "./assets/images/mg-magnette-zb/1.jpg";
import mgMagnetteZB2 from "./assets/images/mg-magnette-zb/2.jpg";
import mgMagnetteZB3 from "./assets/images/mg-magnette-zb/3.jpg";
// import mgMagnetteZB4 from "./assets/images/mg-magnette-zb/4.jpg";
// import mgMagnetteZB5 from "./assets/images/mg-magnette-zb/5.jpg";

import fiatSportSpiderMain from "./assets/images/fiat-sport-spider/main.png";
import fiatSportSpider1 from "./assets/images/fiat-sport-spider/1.jpg";
import fiatSportSpider2 from "./assets/images/fiat-sport-spider/2.jpg";
import fiatSportSpider3 from "./assets/images/fiat-sport-spider/3.jpg";
import fiatSportSpider4 from "./assets/images/fiat-sport-spider/4.jpg";
import fiatSportSpider5 from "./assets/images/fiat-sport-spider/5.jpg";
import fiatSportSpider6 from "./assets/images/fiat-sport-spider/6.jpg";
import { Stack, Typography } from "@mui/material";
import { Youtube } from "./assets/icons/youtube";
import { LinkIcon } from "./components/common";

export const cars: Array<Car> = [
  {
    id: "vw-t1",
    label: "VW T1",
    options: [
      {
        color: "#2c79b0",
        year: "1968",
        image: vwT1ImageMain,
        description: "VW T1 image",
      },
    ],
    description: (
      <Typography variant="h3">
        Volkswagen T1 — автомобиль концерна Volkswagen, производившийся с 1950
        по 1967 годы (по 1975 в Бразилии). Один из первых микроавтобусов.
        Автомобиль также стал лицом целой эпохи и, наряду со своим преемником
        T2, был очень популярен среди хиппи. Также известен как «хиппимобиль»
        или «сплитти».
        <br />
        Дизайн автомобиля выделяется огромным логотипом VW на передней части и
        разделённым на две части лобовым стеклом, что делает его непохожим на
        другие автомобили.
        <br />
        Этот «хиппимобиль» стал одним из самых узнаваемых и ярких символов эпохи
        хиппи.
        <Stack direction="row" alignItems="center" gap={1}>
          Съемки в клипе Максима Фадеева «Новая колыбельная»:
          <LinkIcon
            color="white"
            to="https://youtu.be/J-TFSlpBBF4?si=cro4WFpBo-H-Mtg-"
          >
            <Youtube />
          </LinkIcon>
        </Stack>
      </Typography>
    ),
    images: [
      vwT1Image1,
      vwT1Image2,
      vwT1Image3,
      vwT1Image4,
      vwT1Image5,
      vwT1Image6,
    ],
  },
  {
    id: "vw-t2",
    label: "VW T2",
    options: [
      {
        color: "green",
        year: "1969",
        image: vwT2GreenImageMain,
        description: "VW T2 green",
      },
      {
        color: "#dc3229",
        year: "1976",
        image: vwT2RedImageMain,
        description: "VW T2 red",
      },
    ],
    description: (
      <Typography variant="h3">
        В 1967 году появилось второе поколение Transporter T2. Volkswagen-T2
        стал настоящим долгожителем: в ФРГ он производился до 1979 г., при этом
        часто шел на экспорт, и свои версии этой машины производились в Мексике,
        Бразилии и Аргентине. Последний автомобиль сошел с конвейера аж в 2013
        г. в Бразилии.
        <br />
        Автомобиль-открытка, автомобиль-прикол, автомобиль-тусовка – все это про
        легендарный микроавтобус Volkswagen Transporter второго поколения,
        который как и его старший брат ( Транспортер первого поколения), был
        очень популярен среди «детей солнца» (хиппи). Да и в наше время этот
        бусик способен «ловить» на дороге бесчисленное количество «лайков» и
        проявлений позитива. Он не менее притягательный, чем предшественник и
        такой же заметный сегодня. Это очень атмосферные бусики, пропитанные
        духом доброжелательности и веселья – уже ради этого духа стоит
        воспользоваться нашими микроавтобусами! Очень часто принимают участие в
        одном из самых главных мероприятий в семейной жизни – свадьбе ( в
        качестве автомобиля жениха – зеленый, автомобиля невесты – оранжевый).
        <br />
        <Stack direction="row" alignItems="center" gap={1}>
          А также в различных съемках клипов, кино, рекламе:
          <LinkIcon
            color="white"
            to="https://youtu.be/S4v64aQ6rDQ?si=q4jpBQgkGj4EHflJ"
          >
            <Youtube />
          </LinkIcon>
        </Stack>
      </Typography>
    ),
    images: [
      vwT2Image1,
      vwT2Image2,
      vwT2Image3,
      vwT2Image4,
      vwT2Image5,
      vwT2Image6,
      vwT2Image7,
      vwT2Image8,
      vwT2Image9,
    ],
  },
  {
    id: "vw-thing",
    label: "VW Thing",
    options: [
      {
        color: "#6c7345",
        year: "1974",
        image: vwThingMain,
        description: "VW Thing",
      },
    ],
    description: (
      <Typography variant="h3">
        Этой моделе в Германии было присвоено название под индексом Т181 (просто
        и аскетично), в США назвали "THING"; (дословно ВЕЩЬ или НЕЧТО).
        <br />
        Это потомок или гражданская версия знаменитого немецкого Kubelwagen.
        <br />
        И среди фанатов VW aircooled очень популярна, да и выпущено всего было
        не более 40 тыс шт. А уж сколько дожило до нашего времени никто и не
        знает.
        <br />
        Этакий аскет! ничего лишнего, все функционально и достаточно просто. В
        этом и весь кайф – отсеки все лишнее, создай НЕЧТО!
      </Typography>
    ),
    images: [
      vwThingImage1,
      vwThingImage2,
      vwThingImage3,
      vwThingImage4,
      vwThingImage5,
    ],
  },
  {
    id: "vw-beetle",
    label: "VW Beetle",
    options: [
      {
        color: "#2e2423",
        year: "1972",
        image: vwBeetleMain,
        description: "VW Beetle",
      },
    ],
    description: (
      <Typography variant="h3">
        «Жук» — легковой автомобиль, выпускавшийся немецкой компанией Volkswagen
        с 1946 по 2003 год. "Жук" стал мировым бестселлером за 65 лет своего
        существования, выпущено более 21,5 миллиона автомобилей.
        <br />
        «Жука» с его мягкими округлыми формами, улыбающимся капотом и
        выпученными глазами- фарами отличает яркая индивидуальность, он узнаваем
        сразу. Он был членом семьи: домашнее животное, сидящее в гараже. А его
        оппозитный воздушного охлаждения двигатель с двойной выхлопной системой
        имел уникальный звук, который не спутать ни с чем.
        <br />
        Представленный нами Жук - автомобиль 1972 года, прошедший полную
        реставрацию и технически полностью исправный автомобиль, удовлетворяющий
        всем требованиям современной реальности.
        <br />
        <Stack direction="row" alignItems="center" gap={1}>
          Автомобиль поможет украсить любой праздник и мероприятие.
          <LinkIcon
            color="white"
            to="https://youtu.be/teXSITFSZVU?si=22WdDIM-_aagKOxD"
          >
            <Youtube />
          </LinkIcon>
        </Stack>
      </Typography>
    ),
    images: [
      vwBeetleImage1,
      vwBeetleImage2,
      vwBeetleImage3,
      vwBeetleImage4,
      vwBeetleImage5,
    ],
  },
  {
    id: "vw-karmann-chia",
    label: "VW Karmann Ghia",
    options: [
      {
        color: "#3a1a1c",
        year: "1971",
        image: vwKarmannGhiaMain,
        description: "VW Karmann Ghia",
      },
    ],
    description: (
      <Typography variant="h3">
        Volkswagen Karmann-Ghia Typ 14 — спортивный автомобиль на шасси
        Volkswagen 1200 с кузовами «купе» и «кабриолет», выпускавшийся в ФРГ с
        1955 по 1974 год.
        <br />
        Это немецкий автомобиль с итальянским дизайном, не дающий спокойно
        пройти мимо. А кузов в стиле кабриолет дает возможность с шиком ощутить
        весь кайф от поездки на авто 1971 года.
        <br />
        Данный автомобиль очень популярен в самых различных съемках (кино,
        реклама, климах) и мероприятиях (свадьбы, корпоративы).
        <br />
        Обращайтесь, мы поможем Вам осуществить задуманное!
      </Typography>
    ),
    images: [
      vwKarmannGhia1,
      vwKarmannGhia2,
      vwKarmannGhia3,
      vwKarmannGhia4,
      vwKarmannGhia5,
      vwKarmannGhia6,
      vwKarmannGhia7,
    ],
  },
  {
    id: "mg-magnette-zb",
    label: "MG Magnette ZB",
    options: [
      {
        color: "#353c45",
        year: "1958",
        image: mgMagnetteZBMain,
        description: "VW Karmann Ghia",
      },
    ],
    description: (
      <Typography variant="h3">
        MG Magnette ZB - действующий экземпляр классического английского
        автомобиля из далекого 1958 года ( тогда в Старом Свете ценили
        компактность и экономичность).
        <br />
        Этот представитель английской классики был полностью отреставрирован.
        <br />
        MG MAGNETTE является ЕДИНСТВЕННЫМ на просторах России, да и всего
        бывшего СССР, что делает его уникальным и редким.
      </Typography>
    ),
    images: [
      mgMagnetteZB1,
      mgMagnetteZB2,
      mgMagnetteZB3,
      // mgMagnetteZB4,
      // mgMagnetteZB5,
    ],
  },
  {
    id: "fiat-sport-spider",
    label: "Fiat Sport Spider",
    options: [
      {
        color: "#783035",
        year: "1975",
        image: fiatSportSpiderMain,
        description: "Fiat Sport Spider 1975",
      },
    ],
    description: (
      <Typography variant="h3">
        Настоящий итальянский кабриолет, которому не требуется дополнительная
        реклама!
        <br />В этом авто есть ВСЕ: итальянский стиль, спортивный характер эпохи
        70-х годов 20-го века.
      </Typography>
    ),
    images: [
      fiatSportSpider1,
      fiatSportSpider2,
      fiatSportSpider3,
      fiatSportSpider4,
      fiatSportSpider5,
      fiatSportSpider6,
    ],
  },
];
