import { IconButton } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

type LinkIconProps = {
  to: string;
  color?: string;
  hoverColor?: string;
};

export const LinkIcon: FC<PropsWithChildren<LinkIconProps>> = ({
  to,
  hoverColor = "#acacac",
  color,
  children,
}) => (
  <IconButton
    disableRipple
    sx={{
      padding: 0.25,
      svg: {
        fill: color,
      },
      ":hover": {
        svg: {
          fill: hoverColor,
        },
      },
    }}
  >
    <Link to={to} target="_blank">
      {children}
    </Link>
  </IconButton>
);
