import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { LayoutContent } from "../components/LayoutContent";
import { PageContent } from "../components/PageContent";

export const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route path={"/"} element={<LayoutContent />}>
        <Route path=":id" element={<PageContent />} />
      </Route>
      <Route path="*" element={<div>'OUTSIBD'</div>} />
    </Routes>
  );
};
