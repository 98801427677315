import { Box, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

type SliderProps = {
  images: string[];
};

export const Slider: FC<SliderProps> = ({ images }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      width={isSmallScreen ? "100%" : 790}
      minHeight={isSmallScreen ? 510 : "100%"}
      sx={{
        borderRadius: "10px",
        position: "relative",
        border: "1px solid #eee",
        overflow: "hidden",
      }}
    >
      <ImageGallery
        items={images.map((i) => ({ original: i, thumbnail: i }))}
        showThumbnails
        showPlayButton={false}
        thumbnailPosition={isSmallScreen ? "bottom" : "right"}
        lazyLoad
      />
    </Box>
  );
};
