import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cars } from "../config";

type CarImage = {
  image: string;
  description: string;
};

export type CarOptions = {
  color: string;
  year: string;
} & CarImage;

export type Car = {
  id: string;
  label: string;
  options: Array<CarOptions>;
  description: React.ReactElement | ReactNode;
  images: string[];
};

interface DefaultContext {
  selectedCar?: Car;
  cars: Array<Car>;
}

const defaultContext: DefaultContext = {
  selectedCar: cars[0],
  cars,
};

const CarSwitchContext = createContext(defaultContext);

export const useCarSwitchContext = () => useContext(CarSwitchContext);

export const CarSwitchContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { id: carId } = useParams();
  const navigate = useNavigate();
  const [selectedCar, setSelectedCar] = useState<Car>();

  useEffect(() => {
    const defaultCar = cars[0];
    if (!carId) {
      navigate(`/${defaultCar.id}`);
    }

    const car = cars.find(({ id }) => id === carId);
    if (!car) navigate(`/${defaultCar.id}`);
    else setSelectedCar(car);
  }, [carId, navigate]);

  return (
    <CarSwitchContext.Provider value={{ selectedCar, cars }}>
      {children}
    </CarSwitchContext.Provider>
  );
};
