import { Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { Instagram } from "../assets/icons/instagram";
import { Youtube } from "../assets/icons/youtube";
import { TELEPHONE } from "../constants";
import { LinkIcon } from "./common";
import { Vkontakte } from "../assets/icons/vkontakte";
import { Telegram } from "@mui/icons-material";
import { Whatsapp } from "../assets/icons/whatsapp";

export const Contacts: FC = () => {
  return (
    <Stack
      sx={(theme) => ({
        alignItems: "center",

        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          alignItems: "flex-start",
          pl: 1,
          gap: 0,
        },
      })}
    >
      <Stack direction="row" gap={2.5}>
        <LinkIcon to={"https://www.instagram.com/lavr2009"}>
          <Instagram />
        </LinkIcon>
        <LinkIcon
          to={"https://youtube.com/@sergeylavrovskiy6432?si=641x0gGzYxLJarq5"}
        >
          <Youtube />
        </LinkIcon>
        <LinkIcon to={"https://vk.com/id615118833"}>
          <Vkontakte />
        </LinkIcon>
        <LinkIcon color="#000" to={"https://t.me/retroclassiccar"}>
          <Telegram />
        </LinkIcon>
        <LinkIcon to={"https://api.whatsapp.com/send?phone=79166831041"}>
          <Whatsapp />
        </LinkIcon>
      </Stack>
      <Stack justifyItems="center">
        <Typography noWrap variant="h2" fontWeight={700}>
          {TELEPHONE}
        </Typography>
        <Typography noWrap variant="body1" fontWeight={800}>
          Мы поможем Вам в выборе, <br /> чтобы реализовать Вами задуманное!
        </Typography>
      </Stack>
    </Stack>
  );
};
