import React, { FC } from "react";
import { ThemeProvider } from "@mui/material";
import { AppRoutes } from "./hooks/AppRoutes";
import { theme } from "./theme";

export const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppRoutes />
    </ThemeProvider>
  );
};
