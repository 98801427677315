import React, { FC } from "react";
import { CarSwitchContextProvider } from "../hooks/useCarSwitch";
import { Header } from "./Header";
import { Outlet } from "react-router-dom";

type Props = {};

export const LayoutContent: FC<Props> = (props) => {
  return (
    <CarSwitchContextProvider>
      <Header /> <Outlet />
    </CarSwitchContextProvider>
  );
};
