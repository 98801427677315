import { Box, Stack, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

type FooterProps = {
  description: ReactNode;
};

export const Footer: FC<FooterProps> = ({ description }) => {
  return (
    <Stack

      minHeight="240px"
      direction="row"
      width={"100%"}
      sx={{
        backgroundColor: "#252525",
      }}
      gap={6}
    >
      <Typography width="80%" variant="h3" px={2} pt={2}>
        {description}
      </Typography>

      <Box width={"20px"} pt={2}>
        <Box
          height={"100%"}
          sx={{
            backgroundImage:
              "linear-gradient(to bottom, #fff 70%, #252525 30%)",
            backgroundSize: "20px 60px",
            backgroundRepeat: "repeat-y",
            backgroundColor: "#999",
          }}
        />
      </Box>
    </Stack>
  );
};
